<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <FormulateForm name="formularioRegistro" @submit="saveCustomer" #default="{ isLoading }">
          <FormulateInput
            name="name"
            v-model="form.name"
            label="Nombre Completo"
            input-class="form-control"
            validation="required"
            :validation-messages="{
              required: 'Se Requiere un nombre',
            }"
          />

          <FormulateInput
            name="email"
            v-model="form.email"
            type="email"
            label="Correo electrónico"
            input-class="form-control"
            placeholder="wapidu@wapidu.com"
            validation-name="Email"
            :validation-messages="{
              required: 'Se Requiere un correo electrónico',
              email: 'Por favor, introduce una dirección de correo electrónico válida.',
            }"
            help="* Este campo es obligatorio"
            validation="required|email"
          />
          <FormulateInput
            name="password"
            v-model="form.password"
            type="password"
            label="Contraseña"
            input-class="form-control"
            prepend-icon="mdi-key"
            validation="required|min:8,length"
            validation-name="Password"
            error-behavior="live"
            :validation-messages="{
              required: 'Se Requiere una contraseña',
              min: 'La contraseña debe tener al menos 8 caracteres',
            }"
            help="* Este campo es obligatorio, debe tener al menos 8 caracteres"
          />
          <FormulateInput
            name="mobile_phone"
            v-model="form.mobile_phone"
            type="tel"
            label="Celular o teléfono"
            input-class="form-control"
            validation="required|min:10,length|max:12,length|number"
            validation-name="Celular o teléfono"
            :validation-messages="{
              required: 'Se Requiere un celular o teléfono',
              min: 'El celular o teléfono debe tener al menos 10 caracteres',
              max: 'El celular o teléfono debe tener máximo 12 caracteres',
              number: 'El teléfono solo acepta números enteros',
            }"
            error-behavior="live"
          >
          </FormulateInput>
          <FormulateInput
            name="postal_code"
            v-model="form.postal_code"
            type="number"
            label="Código postal"
            input-class="form-control"
            validation="required|min:5,length|max:5,length|number"
            validation-name="Código postal"
            validation-behavior="live"
            :validation-messages="{
              required: 'Se Requiere un código postal',
              min: 'El código postal debe tener al menos 5 caracteres',
              max: 'El código postal debe tener máximo 5 caracteres',
              number: 'El código postal solo acepta números enteros',
            }"
            :disabled="isLoading"
          />

          <FormulateInput
            type="checkbox"
            v-model="noticeOfPrivacy"
            label="¿Acepto la política de privacidad?"
            input-class="form-control"
            name="noticeOfPrivacy"
            validation-name="Políticas de privacidad"
            validation="accepted|required"
            :validation-messages="{
              required: true,
              accepted: 'Se requiere aceptar la política de privacidad',
            }"
          />
          <FormulateInput
            type="button"
            @click="saveCustomer"
            label="Enviar"
            :disabled="calcFormDisabled()"
          />
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
// API
import AuthAPI from '@/api/auth';
import CustomerAPI from '@/api/customer';
import { mapActions } from 'vuex';

export default {
  name: 'RegistroCuenta',

  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        mobile_phone: '',
        postal_code: '',
      },
      formDisabled: 1,
      noticeOfPrivacy: false,
      showPassword: false,
    };
  },
  created() {
    this.api = new CustomerAPI();
    this.authApi = new AuthAPI();
  },
  methods: {
    ...mapActions('signup', ['getProfileFromStorage', 'getCartFromStorage']),
    calcFormDisabled() {
      if (
        // eslint-disable-next-line
        this.form.name &&
        // eslint-disable-next-line
        this.form.email &&
        // eslint-disable-next-line
        this.form.password &&
        // eslint-disable-next-line
        this.form.mobile_phone &&
        // eslint-disable-next-line
        this.form.postal_code
      ) {
        return false;
      }
      return true;
    },
    async saveCustomer() {
      if (
        // eslint-disable-next-line
        !this.form.name ||
        // eslint-disable-next-line
        !this.form.email ||
        // eslint-disable-next-line
        !this.form.password ||
        // eslint-disable-next-line
        !this.form.mobile_phone ||
        // eslint-disable-next-line
        !this.form.postal_code
      ) {
        this.$store.dispatch('notification/notifyError', 'Por favor completa tus datos');
        return;
      }
      if (!this.noticeOfPrivacy) {
        this.$store.dispatch('notification/notifyError', 'Debes aceptar el aviso de privacidad');
        return;
      }
      this.$store.commit('showLoader');
      try {
        const { uuid } = await this.api.signup(this.form);
        const customer = {
          ...this.form,
          uuid,
        };
        this.$store.commit('signup/setCustomer', customer);
        this.$store.dispatch('notification/notifyError', 'Confirma tu correo electrónico');
        this.form.name = '';
        this.form.email = '';
        this.form.password = '';
        this.form.mobile_phone = '';
        this.form.postal_code = '';
        this.$formulate.reset('formularioRegistro');
      } catch (error) {
        if (error.data.email?.pop() === 'Person with this Email already exists.') {
          this.$store.dispatch('notification/notifyError', 'Este email ya está registrado');
        } else {
          this.$store.dispatch('notification/notifyError', 'Ocurrió un error durante el registro');
        }
      } finally {
        this.$store.commit('hideLoader');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// form-control
</style>
