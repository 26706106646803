<template>
  <div>
    <section class="section-navbar sticky-top">
      <Navbar />
    </section>
    <v-main>
      <Notification />
      <div class="container-md p-3 pt-0 mt-0">
        <div class="row">
          <div class="col-12">
            <div class="p-5 bg-color-wapidu text-white">
              <div class="d-flex justify-content-center">
                <img src="@/assets/img/logo_white.png" alt="checkout" class="img-fluid img-logo" />
              </div>
              <hr />
              <div>
                <h5 class="heading-primary titulo-1 text-center">Checkout</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card">
              <div class="card-header bg-color-wapidu-opacities-01">
                <h3>Datos del Cliente</h3>
              </div>
              <div class="card-body m-4" v-if="myAccount === null">
                <div class="card cardLogin" v-if="showLoginForm === false">
                  <div class="container">
                    <div class="row">
                      <div class="card-body pt-2 pb-2">
                        <div class="row">
                          <div
                            class="col-12 col-md-8 mx-auto d-flex justify-content-center align-items-center"
                          >
                            <h5 class="text-center mb-0">¿Ya tienes cuenta Con Wapidu?</h5>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-12 col-md-4 mx-auto d-flex justify-content-center align-items-center"
                          >
                            <button
                              @click="switchLoginJoinForms()"
                              class="btn bg-color-wapidu color-blanco mb-0"
                            >
                              Inicia Sesión
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-12mx-auto d-flex justify-content-center align-items-center"
                          >
                            <a href="/login/facebook"><v-icon>mdi-facebook</v-icon></a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="/login/google"><v-icon>mdi-google</v-icon></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <hr />
                      </div>
                      <div class="row">
                        <div
                          class="col-12 mx-auto d-flex justify-content-center align-items-center"
                        >
                          <h5 class="text-center mb-0">O crea tu cuenta</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <RegistroCuenta />
                </div>
                <div class="card cardLogin" v-else>
                  <div class="container">
                    <div class="row">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-6">
                            <h4 class="text-center">Regresar a:</h4>
                          </div>
                          <div class="col-6">
                            <button
                              @click="switchLoginJoinForms()"
                              class="btn bg-color-wapidu color-blanco justify-content-center"
                            >
                              Crear Cuenta
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row d-flex justify-content-center align-items-center">
                      <div class="col d-flex justify-content-center align-items-center">
                        <FrontendLogin />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body m-4" v-else>
                <div class="card cardLogin">
                  <div class="container">
                    <div class="row">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12" v-if="this.myAccount.last_name">
                            <h4>Hola {{ this.myAccount.full_name }}</h4>
                          </div>
                          <div class="col-12" v-else>
                            <p>
                              Completa los datos de
                              <a href="/#/account">tu perfil</a> para continuar
                            </p>
                          </div>
                          <div class="col-12" v-if="this.myAccount.address">
                            Tu direción de envío:<br />{{ this.myAccount.address.full_address }}
                          </div>
                          <div class="col-12" v-else>
                            <p>
                              Necesitas agregar <a href="/#/account">tu direción</a> para continuar.
                            </p>
                          </div>
                          <div class="col-12">
                            <hr />
                            <p>
                              <small
                                >Si deseas usar otra cuenta puedes
                                <a href="/logout">Cerrar Sesión.</a></small
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card">
              <div class="card-header bg-color-wapidu-opacities-01">
                <h3 class="">Detalles del pedido</h3>
              </div>
              <div class="card-body">
                <h5 class="card-title mb-4">Resumen de sus productos</h5>

                <div
                  class="d-flex justify-content-between mb-3"
                  v-for="item in cart"
                  :key="item.id"
                >
                  <div class="d-flex flex-column">
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="mt-1" v-if="item.service.toLowerCase().indexOf('sus') > -1">
                    <small class="super-price">{{ item.clothes_quantity }} ⅹ </small>
                    <small class="super-price"
                      >$ {{ item.unit_price * shipmentMultiplier }} / Mes</small
                    >
                  </div>
                  <div class="mt-1" v-else>
                    <small class="super-price">{{ item.clothes_quantity }} ⅹ </small>
                    <small class="super-price">$ {{ item.unit_price * shipmentMultiplier }}</small>
                  </div>
                </div>

                <hr class="my-4 line" />

                <div class="d-flex justify-content-between mb-2">
                  <span>SubTotal</span>
                  <span class="text-secondary">$ {{ cartTotal }}</span>
                </div>
                <hr class="my-4 line" />
                <div class="d-flex justify-content-between mb-2">
                  <div class="d-flex flex-column">
                    <h4>Tipo de envío</h4>
                    <p><small>* El envió urgente aumenta 30% el costo de los servicios.</small></p>
                    <FormulateForm name="formRadioEnvio">
                      <div class="form-check">
                        <FormulateInput
                          v-model="precioEnvio"
                          v-for="item in envio"
                          :key="item.id"
                          name="Precio"
                          :label="'$ ' + item.price_Envio + ' ' + item.tipo_Envio"
                          :value="item.price_Envio"
                          type="radio"
                          validation="required"
                          :validation-messages="{
                            required: 'Se  que selecciones un metodo envio',
                          }"
                        />
                      </div>
                    </FormulateForm>
                  </div>
                </div>
                <div class="py-3">
                  <div class="d-flex justify-content-between mb-2">
                    <span>Total</span>
                    <span class="text-primary">$ {{ sumPrecioEnvio().toFixed(2) }}</span>
                  </div>
                </div>
                <hr class="mt-0 line" />
                <!--
                <hr class="mt-0 line" />
                <div class="p-3 d-flex justify-content-between">

                </div>
                <hr class="mt-0 line" />
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Tienes un cupón?
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div class="accordion-body">
                        <div class="p-3">
                          <form class="row g-3">
                            <div class="col-auto">
                              <label for="staticEmail2" class="visually-hidden">Codigo</label>
                              <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="staticEmail2"
                                value="Codigo de descuento"
                              />
                            </div>
                            <div class="col-auto">
                              <label for="inputPassword2" class="visually-hidden">Codigo</label>
                              <input
                                type="text"
                                class="form-control text-uppercase"
                                id="inputPassword2"
                                :value="discount_code"
                                placeholder="Codigo"
                              />
                            </div>
                            <v-col class="pa-0" cols="12">
                              <span class="text-primary">
                                {{ discount_alert.message }}
                              </span>
                            </v-col>
                            <div class="col-auto">
                              <button
                                type="submit"
                                class="btn bg-color-wapidu color-blanco mb-3"
                                @click="applyDiscount"
                              >
                                Confirmar Codigo
                              </button>
                              <button
                                type="submit"
                                class="btn bg-color-wapidu color-blanco mb-3"
                                @click="ValorDescuent()"
                              >
                                Confirmar Codigo
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                -->
                <!-- div class="row">
                  <div class="col-6">
                    <FormulateInput
                      type="button"
                      @click="programaticSubmit"
                      label="Enviar"
                      :disabled="cart.length === 0"
                    />
                  </div>
                </div -->
                <div class="row">
                  <div class="col-6">
                    <a
                      :class="'btn btn-primary ' + (!myAccount ? 'disabled' : '')"
                      :disabled="!myAccount"
                      :href="continueLink"
                    >
                      {{ ctaLabel }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center mt-3 mb-3">
              <img src="@/assets/img/payment/pago-stripe.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
// Libraries
import moment from 'moment';
// Components
import { mapGetters, mapState, mapActions } from 'vuex';
import Notification from '@/components/notification/index.vue';
import DiscountAPI from '@/api/discount';
import CustomerAPI from '@/api/customer';
import Navbar from '@/components/navbar/index.vue';
import FrontendLogin from '@/views/login/component/frontendLogin.vue';
import RegistroCuenta from '@/views/signup/components/RegistroCuenta.vue';
import AuthAPI from '@/api/auth';

// import StepOne from './components/StepOne.vue';

export default {
  name: 'Signup',
  data() {
    return {
      myAccount: null,
      showLoginForm: false,
      shipmentType: '',
      shipmentMultiplier: 1,
      tipoEnvio: [],
      precioEnvio: (40).toFixed(2),
      codigo: '',
      totalGeneral: 0,
      discount_alert: {
        message: null,
        type: 'red--text',
      },
      ctaLabel: 'Continuar',
      continueLink: '',
      discount_code: null,
      discount: null,
      currentDate: moment().format('YYYY-MM-DD'),
    };
  },
  async created() {
    console.log('created');
    this.tipoEnvio = this.getEnvioss();
    this.discountAPI = new DiscountAPI();
    this.api = new CustomerAPI();
    this.authApi = new AuthAPI();

    this.authApi
      .profile()
      .then((profile) => {
        console.log('profile');
        console.log(profile);
        this.myAccount = profile;
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
      })
      .finally(() => {
        this.updateNextStep()
        this.$store.commit('hideLoader');
      });

  },
  computed: {
    ...mapGetters('signup', ['cartTotal', 'cartTotalUrgent']),
    ...mapState('signup', ['cart', 'envio', 'initialCartFilled']),
  },
  methods: {
    ...mapActions('signup', ['getEnvioss']),
    precioEn() {
      console.log(this.precioEnvio);
    },
    sumPrecioEnvio() {
      let totalCalc = 0;
      const shipmentPrice = Math.floor(this.precioEnvio);
      this.shipmentType = shipmentPrice === 50 ? 'URGENT' : 'REGULAR';
      if (this.shipmentType === 'URGENT') {
        this.shipmentMultiplier = 1.3;
      } else {
        this.shipmentMultiplier = 1;
      }
      this.cart.map((item) => {
        totalCalc += Math.round(item.clothes_quantity * item.unit_price * this.shipmentMultiplier);
        return item;
      });
      if (shipmentPrice) {
        totalCalc += shipmentPrice;
      }
      return totalCalc;
    },
    updateNextStep() {
      if (this.myAccount) {
        this.ctaLabel ='Completar Compra'
        this.continueLink = '/#/account?continue';
        if (this.myAccount.last_name) {
          if (this.myAccount.address && this.myAccount.address.id) {
            this.continueLink = '/#/orders/form?autoFill';
          }
        }
      }else{
        this.ctaLabel = 'Inicia Sesión para Continuar'
      }
      return null;
    },
    async applyDiscount() {
      this.$store.commit('showLoader');
      try {
        this.$store.commit('signup/setDiscount', null);
        this.discount = null;
        this.discount = await this.discountAPI.getByCode({
          code: this.discount_code,
          // office: this.office.uuid,
        });
        this.discount_alert = {
          message: `Descuento del ${this.discount.discount}% aplicado.`,
          type: 'green--text',
        };
        this.$store.commit('signup/setDiscount', this.discount);
      } catch (error) {
        // console.log(error);
        this.discount_alert = {
          message: error.data.message,
          type: 'red--text',
        };
      }
      this.$store.commit('hideLoader');
    },
    async validacionEnvio(data) {
      const numeroPrecio = parseInt(data, 10);
      if (numeroPrecio < 1) {
        console.log('validacionEnvio2', data);
      } else {
        console.log('validacionEnvio4', data);
      }
    },
    switchLoginJoinForms() {
      this.showLoginForm = !this.showLoginForm;
    },
    async programaticSubmit() {
      try {
        this.$formulate.submit('formRadioEnvio');
        // this.$formulate.submit('formularioRegistro');
      } catch (error) {
        console.log('NO Funciona');
      }
    },
  },
  components: {
    Notification,
    Navbar,
    FrontendLogin,
    RegistroCuenta,
  },
};
</script>

<style lang="scss" scoped>
Form {
  display: flex;
}

v-toolbar {
  background: rgb(27, 29, 77);
}

.bg-color-wapidu {
  background: rgb(27, 29, 77);
  background: linear-gradient(16deg, rgba(27, 29, 77, 1) 0%, rgba(63, 81, 181, 1) 59%);
}

.bg-color-wapidu-opacities-01 {
  background: rgba(27, 29, 77, 0.174);
  background: linear-gradient(16deg, rgba(27, 29, 77, 0.1) 0%, rgba(63, 81, 181, 0.1) 59%);
}

.titulo-1 {
  font-size: 2.5rem !important;
  font-weight: 700;
  color: #fff;
}

.color-blanco {
  color: #fff;
}

.color-blanco:hover {
  color: #d68c1d;
}

.img-logo {
  width: 25%;
  height: auto;
}

@media (max-width: 768px) {
  .titulo-1 {
    font-size: 2.5rem !important;
  }

  .img-logo {
    width: 50%;
    height: auto;
  }
}

.cardLogin {
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}
.cardLogin .container {
  padding: 0 0 2rem 0;
}
.cardLogin .container:last-child {
  padding-bottom: 0;
}
</style>
