var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('FormulateForm',{attrs:{"name":"formularioRegistro"},on:{"submit":_vm.saveCustomer},scopedSlots:_vm._u([{key:"default",fn:function({ isLoading }){return [_c('FormulateInput',{attrs:{"name":"name","label":"Nombre Completo","input-class":"form-control","validation":"required","validation-messages":{
            required: 'Se Requiere un nombre',
          }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('FormulateInput',{attrs:{"name":"email","type":"email","label":"Correo electrónico","input-class":"form-control","placeholder":"wapidu@wapidu.com","validation-name":"Email","validation-messages":{
            required: 'Se Requiere un correo electrónico',
            email: 'Por favor, introduce una dirección de correo electrónico válida.',
          },"help":"* Este campo es obligatorio","validation":"required|email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('FormulateInput',{attrs:{"name":"password","type":"password","label":"Contraseña","input-class":"form-control","prepend-icon":"mdi-key","validation":"required|min:8,length","validation-name":"Password","error-behavior":"live","validation-messages":{
            required: 'Se Requiere una contraseña',
            min: 'La contraseña debe tener al menos 8 caracteres',
          },"help":"* Este campo es obligatorio, debe tener al menos 8 caracteres"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('FormulateInput',{attrs:{"name":"mobile_phone","type":"tel","label":"Celular o teléfono","input-class":"form-control","validation":"required|min:10,length|max:12,length|number","validation-name":"Celular o teléfono","validation-messages":{
            required: 'Se Requiere un celular o teléfono',
            min: 'El celular o teléfono debe tener al menos 10 caracteres',
            max: 'El celular o teléfono debe tener máximo 12 caracteres',
            number: 'El teléfono solo acepta números enteros',
          },"error-behavior":"live"},model:{value:(_vm.form.mobile_phone),callback:function ($$v) {_vm.$set(_vm.form, "mobile_phone", $$v)},expression:"form.mobile_phone"}}),_c('FormulateInput',{attrs:{"name":"postal_code","type":"number","label":"Código postal","input-class":"form-control","validation":"required|min:5,length|max:5,length|number","validation-name":"Código postal","validation-behavior":"live","validation-messages":{
            required: 'Se Requiere un código postal',
            min: 'El código postal debe tener al menos 5 caracteres',
            max: 'El código postal debe tener máximo 5 caracteres',
            number: 'El código postal solo acepta números enteros',
          },"disabled":isLoading},model:{value:(_vm.form.postal_code),callback:function ($$v) {_vm.$set(_vm.form, "postal_code", $$v)},expression:"form.postal_code"}}),_c('FormulateInput',{attrs:{"type":"checkbox","label":"¿Acepto la política de privacidad?","input-class":"form-control","name":"noticeOfPrivacy","validation-name":"Políticas de privacidad","validation":"accepted|required","validation-messages":{
            required: true,
            accepted: 'Se requiere aceptar la política de privacidad',
          }},model:{value:(_vm.noticeOfPrivacy),callback:function ($$v) {_vm.noticeOfPrivacy=$$v},expression:"noticeOfPrivacy"}}),_c('FormulateInput',{attrs:{"type":"button","label":"Enviar","disabled":_vm.calcFormDisabled()},on:{"click":_vm.saveCustomer}})]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }